<template>
    <div class="d-flex align-center justify-center login full-height">
        <div class="bg-wrapper full-height full-width">
            <div class="overlay full-height full-width"></div>
            <div class="bg-img full-height full-width"></div>
        </div>
         <v-card elevation="10" class="login-card rounded-lg pa-3 px-10 d-flex align-center full-width flex-column" width="500" outlined >
            <v-card-title class="text-center font-weight-regular text-h6">{{$t("loginWelcomeTitle")}}</v-card-title>
            <div class="full-width">
                <div class="input-group">
                    <p class="mb-1 grey--text text-caption font-weight-medium">{{$t("loginEmailLabel")}}</p>
                    <v-text-field class="font-weight-bold text-body-2" v-model="login" hide-details outlined dense></v-text-field>
                </div>
                <div class="my-4 d-flex flex-column align-start input-group full-width">
                    <p class="mb-1 grey--text text-caption font-weight-medium">{{$t("loginPasswordLabel")}}</p>
                    <v-text-field class="font-weight-bold text-body-2 full-width" v-model="password" type="password" hide-details outlined dense></v-text-field>
                    <p class="align-self-end ma-0 mt-1 primary--text text-caption font-weight-medium text-decoration-underline">{{$t("loginPasswordLostLabel")}}</p>
                </div>
            </div>
            <v-card-actions class="full-width d-flex flex-column align-center">
                <CustomButton @click="validLogin" fullWidth>{{$t("loginLoginBtnLabel")}}</CustomButton>
                <p class="ma-0 mt-4 primary--text text-caption font-weight-medium text-decoration-underline">{{$t("loginRegisterBtnLabel")}}</p>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'Login',
    data: () => {
        return {
            valid: true,
            login: "romain.querleu@worldline.com",
            password: "azerty"
        }
    },
    created(){
        this.updatePageInfo(this.$route.meta)
    },
    methods: {
        validLogin(){
            localStorage.setItem("IEC_BO_LOGIN", "true")
            this.$router.push({name: 'Dashboard'})
        }
    }
}
</script>
<style scoped>
    .login{
        position: relative;
    }
    .bg-wrapper{
        position: absolute;
        top: 0;
        left: 0;
    }
    .bg-wrapper .overlay{
        position: absolute;
        background: radial-gradient(#201b1bde, #000000f2);
    }
    .bg-img{
        background-position: center;
        background-size: cover;
        background-image: url("../assets/img/login-bg.jpg")
    }
</style>
